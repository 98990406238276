
<template>
  <div id="app" :style="{ height: screenHeight + 'px' }">
    <keep-alive :include="keepList">
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import './style/common.scss';
import HelloWorld from './components/HelloWorld.vue';
// import MonitorKeyboard from './config/monitor_keyboard.js'
import $ from 'jquery';
export default {
  name: 'App',
  data() {
    return {
      screenHeight: document.body.clientHeight,
      keepList: ['index', 'theme']
    }
  },
  components: {
    HelloWorld,
  },
  watch: {
    $route(to, from) {
      var list = []
      if (global.index_alive) {
        list.push('index')
      }
      if (global.theme_alive) {
        list.push('theme')
      }
      // if (global.link_alive){
      //   list.push('link_add')
      // }
      this.keepList = list
    }
  },
  mounted() {
    // var _this = this
    // setInterval(()=>{
    //   _this.screenHeight = window.innerHeight
    // },100)
    this.getFileFromUrl('https://m.link.dev.liveplus.fun/share_cover.jpg', 'share.jpg')
      .then((response) => {
        global.sharefile = response
      })
      .catch((e) => {
        console.error(e)
      });
    // this.getKeyboardState();

  },
  methods: {
    getKeyboardState() {
    },
    getFileFromUrl(url, fileName) {
      return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader('Accept', 'image/jpeg');
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response;
          let file = new File([blob], fileName, { type: 'image/jpeg' });
          // 返回结果
          resolve(file);
        };
        xhr.onerror = (e) => {
          reject(e)
        };
        // 发送
        xhr.send();
      });
    },
  }
};
</script>

<style lang='scss' scope>
// div{
//   font-size: 0.14rem;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  max-width: 750px;
  overflow-y: scroll;
  width: 100%;
  height: 90vh;
  position: relative;

  // margin-top: 60px;
  .title {
    font-size: 0.2rem;
  }

  .title1 {
    font-size: 20px;
    font-weight: bold;
  }

  .box {
    width: 1rem;
    height: 1rem;
    // background-color: red;
  }
}
</style>
