import VueRouter from 'vue-router';
import Vue from 'vue';
import { loginStep } from './redirect';

function views(name) {
    return () => {
        return import('../views' + name);
    };
}

const createRouter = () => new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            beforeEnter: (to, from, next) => {
                global.index_alive = true
                next({
                    path: '/index',
                    replace: true
                })
            }
        },
        // {
        //     path: '/:id',
        //     beforeEnter: (to, from, next) => {
        //         next({
        //             path: '/index',
        //             replace: true
        //         })
        //     }
        // },
        {
            path: '/demo',
            name: 'demo',
            component: views('/demo.vue'),
        },
        {
            path: '/demo2',
            name: 'demo2',
            component: views('/demo2.vue'),
        },
        {
            path: '*',
            component: views('/not_found.vue'),
            beforeEnter: (to, from, next) => {
                var path = to.path
                if (path.length == 33) {
                    var strInfo = localStorage.getItem('userInfo')
                    if (strInfo) {
                        var userInfo = JSON.parse(strInfo)
                        if (userInfo.id == path.substring(1, path.length)) {
                            next({
                                path: '/index',
                                replace: true
                            })
                            return
                        }
                    }
                    next({
                        path: '/index' + path,
                        replace: true
                    })
                    return
                }
                next({
                    path: '/index',
                    replace: true
                })
            }
        },
        {
            path: '/index',
            name: 'index',
            component: views('/index.vue'),
            meta: {
                keepAlive: true
            },
            beforeEnter: (to, from, next) => {
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/index/:id',
            meta: {
                keepAlive: false
            },
            component: views('/user_index.vue')
        },
        {
            path: '/preview',
            component: views('/preview.vue'),
            beforeEnter: (to, from, next) => {
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: views('/welcome.vue'),
            beforeEnter: (to, from, next) => {
                if (window.localStorage.getItem('link')) {
                    var link = JSON.parse(window.localStorage.getItem('link'))
                    next({
                        path: '/add_link',
                        query: {
                            social: JSON.stringify(link.social),
                            is_edit: link.isEdit,
                            social_id: link.social_id,
                            source: 'cache'
                        }
                    })
                    return
                }
                if (global.hasLogin == true) {
                    next({
                        path: '/',
                        replace: true
                    })
                    // this.$router.replace({ path: '/' })
                    return
                }
                next()
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: views('/signup.vue')
        },
        {
            path: '/bind_account',
            component: views('/bind_account.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: views('/login.vue'),
            beforeEnter: (to, from, next) => {
                if (loginStep(to, from, next)) {
                    next()
                }
            }
        },
        {
            path: '/vip',
            name: 'invite_code',
            component: views('/invite_code.vue'),
            beforeEnter: (to, from, next) => {
                if (global.hasLogin == true) {
                    history.back()
                    // window.location.href = '/'
                    return
                }
                next()
            }
        },
        {
            path: '/verify',
            component: views('/verify_code')
        },
        {
            path: '/platform',
            name: 'platform',
            component: views('/platform.vue')
        },
        {
            path: '/platform/link',
            component: views('/add_link.vue'),
            // meta:{
            //     keepAlive:true
            // },
        },
        {
            path: '/signup/profile',
            name: 'edit_nick_info',
            component: views('/base_info_step1.vue'),
            beforeEnter: (to, from, next) => {
                if (from.path == '/signup/profile/extra') {
                    next({
                        path: '/signup/profile/extra',
                        replace: false
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/signup/profile/extra',
            name: 'edit_base_info',
            component: views('/base_info_step2.vue')
        },
        {
            path: '/settings/theme',
            name: 'theme',
            component: views('/choose_theme.vue'),
            beforeEnter: (to, from, next) => {
                // window.history.replaceState(null, null, window.localtion.href);
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            },
            meta: {
                keepAlive: true
            },
        },
        {
            path: '/platform/all',
            component: views('/choose_social.vue')
        },
        {
            path: '/settings/history',
            component: views('/history'),
            beforeEnter: (to, from, next) => {
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/settings/account',
            component: views('/account'),
            beforeEnter: (to, from, next) => {
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/settings/profile',
            component: views('/edit_info'),
            beforeEnter: (to, from, next) => {
                if (global.hasLogin != true) {
                    next({
                        path: '/welcome',
                        replace: true
                    })
                    return
                }
                next()
            }
        },
        {
            path: '/user',
            component: views('/user'),
            beforeEnter: (to, from, next) => {

                next()
            },
            meta: {
                keepAlive: true
            }
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        // console.log('to:' + to.name + '  from:' + from.name);
        // history.pushState(null,null,document.URL)
        return { x: 0, y: 0 }
        //   to.meta._savedPosition = savedPosition;
        //   return savedPosition;
    }
});

const router = createRouter()

router.onError(function (err) {
    // loading chunk
    if (/loading chunk [\s\S]* failed./i.test(err.message)) {
        //   Vue.prototype.$toast({ message: '页面已更新或网络错误，请刷新重试', duration: 2000, iconClass: 'icon icon-error' });
    }
});

router.onReady = () => { };

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
}

export default router;

