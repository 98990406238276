import Vue from 'vue'
import App from './App.vue'

import './assets/icons'
import plugin from './plugin'
import './style/common.scss'
import VueRouter from 'vue-router';
import Toast from './components/toast';
import Modal from './components/modal';
import { get, post, put, del } from './config/http';
import Meta from 'vue-meta';
import api from './config/api';
import './assets/fonts/font.css'
import VConsole from 'vconsole'

let vConsole = new VConsole()

Vue.use(vConsole)

Vue.use(Toast);
Vue.use(Modal);
Vue.use(Meta);
Vue.use(VueRouter);
Vue.use(plugin, { imports: [] })
Vue.config.productionTip = false

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$api = api;


import router from './config/router';
import { globalRedirect } from './config/redirect'


// new Vue({
//   render: h => h(App),
// }).$mount('#app')

function renderApp() {
  // localStorage.removeItem('clientHeight')
  global.index_alive = true
  if (window.localStorage.getItem('hasLogin') == '1') {
    global.hasLogin = true
    global.token = window.localStorage.getItem('token')
    global.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    console.log(global.userInfo)
  }
  else {
    global.hasLogin = false
    global.token = window.localStorage.getItem('token')?window.localStorage.getItem('token'):''
  }
  router.beforeEach((to, from, next) => {
    // window.history.replaceState(null, null, window.localtion.href);
    if (!globalRedirect(to,from.next)){
      return
    }
    global.canChangeRoute = false
    next()
  })

  global.router = router

  const app = new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')

  // window.addEventListener(('popstate',function(){
  //   history.pushState(null,null,document.URL)
  // }))

  window.App = app;

  // alert('ppp'+document.documentElement.clientHeight)
}

renderApp();


