import axios from "axios";
// import { reject, resolve } from "core-js/fn/promise";
import api from "./api";

axios.defaults.baseURL = api.baseUrl;
axios.defaults.timeout = 10000;
// axios.defaults.headers.post['X-Client-Type'] = 'WEB';
// axios.defaults.headers.post['X-Client-Version'] = '1.0';
// axios.defaults.headers.post['X-App-Version'] = '1.0';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMmY2MGEzZjVkYjdjZjJhOGRjZWQxZGMzM2FlMDEwNDkiLCJpc3MiOiJDT0RFUEFBUy5DT00iLCJuaWNrbmFtZSI6Iua_gOWKqOa_gOWKqOWIsOWutiIsInR5cCI6IkJlYXJlciIsInNlc3Npb25fc3RhdGUiOiI2NDA4ZmY4NzFhY2JhOTEzYTE0NWMyN2MxMzAwYmMzNCIsImV4cCI6MTY5NDY3NzYxMywiaWF0IjoxNjYzMTQxNjEzfQ.XKFRxTk3sYd6-F4e_Ibs3dVTntlGcI8Zn3RN-bNb0qw';

axios.interceptors.request.use(config => {
    config.headers['X-Client-Type'] = 'WEB';
    config.headers['X-Client-Version'] = '1.0';
    config.headers['X-App-Version'] = '1.0';
    config.headers['Access-Control-Allow-Origin'] = '*';
    if (global.token) {
        config.headers['Authorization'] = `Bearer ${global.token}`
    }
    // config.headers['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMmY2MGEzZjVkYjdjZjJhOGRjZWQxZGMzM2FlMDEwNDkiLCJpc3MiOiJDT0RFUEFBUy5DT00iLCJuaWNrbmFtZSI6Iua_gOWKqOa_gOWKqOWIsOWutiIsInR5cCI6IkJlYXJlciIsInNlc3Npb25fc3RhdGUiOiJlNWI0MGI4ZmVjNzE3YzgwMDU0N2E1N2Y4MTViMjMyNCIsImV4cCI6MTY5NTEzNjg0MSwiaWF0IjoxNjYzNjAwODQxfQ.IW2LUDl_T5khEAQxSA4K2XU6iIjjK09CYySoScK53SM';

    return config;
}, err => {
    return Promise.error(err);
})

axios.interceptors.response.use(response => {
    if (response.status == 200) {
        return Promise.resolve(response)
    }
    else if (response.status == 204) {
        return Promise.resolve('')
    }
    else if (response.status == 401) {
        // localStorage.clear()
        // this.$router.push('/welcome')
    }
    else {
        return Promise.reject(response)
    }
}, error => {
    return Promise.reject(error.response)
})

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            if (err.status == 401) {
                localStorage.clear()
                location.href = '/welcome'
                // this.$router.push('/welcome')
            }
            reject(err)
        })
    });
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url,
            params
        ).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url,
            params
        ).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export function del(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}