import ModalComponent from './modal.vue'

const Modal = {};

// 注册Toast
Modal.install = function (Vue) {
    // 生成一个Vue的子类
    // 同时这个子类也就是组件
    const ModalConstructor = Vue.extend(ModalComponent)
    // 生成一个该子类的实例
    const instance = new ModalConstructor();

    // 将这个实例挂载在我创建的div上
    // 并将此div加入全局挂载点内部
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 通过Vue的原型注册一个方法
    // 让所有实例共享这个方法 
    Vue.prototype.$modal = (icon,msg,confirmTitle,cancelTitle,confirmCallback,cancelCallback) => {
        instance.message = msg;
        if (icon) {
            instance.icon = icon;
        }
        else {
            instance.icon = '';
        }
        instance.confirmTitle = confirmTitle
        instance.cancelTitle = cancelTitle
        instance.confirmCallback = confirmCallback
        instance.cancelCallback = cancelCallback
        instance.show = true;

        global.modal = instance;

    }
}

export default Modal