<template>
    <!-- <transition name="fade"> -->
      <div class="toast" v-show="show">
        <div class="toast-content">
          <img class="toast-icon" v-if="icon.length>0" :src="icon" alt="">
          <div class="toast-text" v-if="message.length>0">{{message}}</div>
          <a class="link_btn_alert alert-button" style="margin-top:0.16rem" @click="confirmCallback()">{{confirmTitle}}</a>
          <a class="link_btn_alert_cancel alert-button" style="margin-top:0.2rem;margin-bottom: 0.2rem;" @click="cancelCallback()">{{cancelTitle}}</a>
        </div>
  
      </div>
  
    <!-- </transition> -->
  </template>
  
  <script>
  export default {
    data() {
      return {
        show: false,
        message: "",
        icon:'',
        confirmTitle:"",
        cancelTitle:"",
        confirmCallback:null,
        cancelCallback:null
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .toast {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000BF;
    z-index: 99999;
  
    .toast-content {
      padding: 0.24rem 0.32rem;
      background: rgba(77, 77, 77, 0.7);
      border-radius: 0.24rem;
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .toast-icon{
      width: 0.44rem;
      height: 0.44rem;
      margin-bottom: 0.14rem;
    }
  
    .toast-text {
      color: #fff;
      font-size: 0.16rem;
      font-weight: bold;
      white-space: pre-wrap;
      text-align: center;
      line-height: 0.28rem;
    }

    .alert-button{
        width: 2.24rem;
        height: 0.44rem;
    }
  
    .toast-loading{
      width: 0.48rem;
      height: 0.48rem;
      animation: loading 1s 0s linear infinite;
    }
  
    @keyframes loading {
      // 0%{
      //   transform: rotate(0);
      // }
      100%{
        transform: rotate(360deg);
      }
    }
  }
  
//   .fade-enter-active,
//   .fade-leave-active {
//     transition: 0.3s ease-out;
//   }
  
//   .fade-enter {
//     opacity: 0;
//     transform: scale(1.2);
//   }
  
//   .fade-leave-to {
//     opacity: 0;
//     transform: scale(0.8);
//   }
  </style>