const api = {
    baseUrl:'https://m.link.dev.liveplus.fun/api/',
    ossFormUpload:'thirdparty/aliyun/oss-form-upload',
    token:'token',
    userSocials : 'user-socials',
    userStyles : 'user-styles',
    loginVerifyCode : 'sms/login-verify-code',
    inviteCodeExist : 'user/invite-code',
    phoneCanRegister : 'user/sms/can-register',
    emailCanRegister : 'user/email/can-register',
    phoneSendCode : 'user/sms/send-code',
    emailSendCode : 'user/email/send-code',
    phoneLogin : 'user/sms/login',
    emailLogin : 'user/email/login',
    phoneBind : 'user/sms/bind',
    emailBind : 'user/email/bind',
    login : 'user/login',
    userInfo : 'user/info',
    topSocials : 'popular-socials',
    socials : 'socials',
    frontData : 'user/front-data',
    frontDatas : 'user/front-datas',
    recommend : 'recommend-users',
    themeStyles : 'theme-styles',
    themeGroups : 'theme-groups',
    userThemes : 'user-styles',
    themes : 'themes',
    history : 'user-footprints',
    qr : 'qr',

}

export default api;