
export function loginStep(to, from, next) {
    if (to.query.reset == 1) {
        return true
    }
    var step = global.loginStep
    if (step == 1.0) {
        next(
            {
                path: '/signup/profile',
                replace: false
            }
        )
        return false
    }
    if (step == 1.1) {
        next({
            path: '/signup/profile/extra',
            replace: false
        })
        return false
    }

    if (step == 2.1) {

        if (localStorage.getItem('lastSocial') && localStorage.getItem('lastSocial') != 'null') {
            next({
                path: '/platform/link',
                query: { social: localStorage.getItem('lastSocial') },
                replace: false
            })
            return false
        }
        step = 1.2
    }
    if (step == 1.2) {
        next({
            path: '/platform',
            replace: false
        })
        return false
    }
    return true
}

export function globalRedirect(to, from, next) {
    var obj = global.router
    var current = obj.history.current
    if (current.path == '/index' && !global.canChangeRoute) {
        return false
    }
    var array = ['/verify','/signup','/welcome','/vip','/login'];
    if (current.path == '/signup/profile' && array.indexOf(to.path)!=-1){
        next({
            path: '/signup/profile',
            replace: false
        })
        return false
    }
    array.push('/signup/profile')
    if (current.path == '/signup/profile/extra' && array.indexOf(to.path)!=-1) {
        next({
            path: '/signup/profile/extra',
            replace: false
        })
        return false
    }
    array.push('/signup/profile/extra')
    if (current.path == '/platform' && array.indexOf(to.path)!=-1) {
        next({
            path: '/platform',
            replace: false
        })
        return false
    }
    // array.push('/signup/profile/extra')
    if (current.path == '/platform/all' && array.indexOf(to.path)!=-1) {
        next({
            path: '/platform/all',
            replace: false
        })
        return false
    }
    return true
}